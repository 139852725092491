<section class="banner-area" >
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1>Metaverse Development Company</h1>
                            <p>Unlock unimagined prowess of multi-model, immersive worlds with our reliable Metaverse Services. Turn your dream Project into a high-precision 3D, AR/VR reality. Merge digital assets, virtual environments and people with the programming magic of  AI, Blockchain, Web 3.0 and Spatial computing.
                            </p>
                            <div class="banner-btn">
                                <a (click)="scroll(target)" class="box-btn">Contact Now!</a>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-lg-6">
                        <div class="banner-img">
                            <img src="assets/images/home-bg-1-img.png" alt="banner-img" />
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <div class="home-shape">
        <div class="shape1"><img loading="lazy" src="assets/images/shape/1.png" alt="shape"></div>
        <div class="shape2"><img loading="lazy" src="assets/images/shape/2.png" alt="shape"></div>
        <div class="shape3"><img loading="lazy" src="assets/images/shape/3.png" alt="shape"></div>
        <div class="shape4"><img loading="lazy" src="assets/images/shape/4.png" alt="shape"></div>
        <div class="shape5"><img loading="lazy" src="assets/images/shape/5.png" alt="shape"></div>
        <div class="shape6"><img loading="lazy" src="assets/images/shape/6.png" alt="shape"></div>
    </div>
</section>

<section class="home-service-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Smart Services</span>
            <h2>Our Metaverse Development Services</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img loading="lazy" src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Metaverse Consulting Services</h3>
                        <p>Our experienced <b>blockchain experts</b> assist you & provide detailed insights for any Metaverse projects in order to increase its performance, quality & experience. </p>
                        
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img loading="lazy" src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Metaverse Game Development</h3>
                        <p>Metaverse games are integrated with Emerging technologies- Blockchain, Web 3.0, Augmented reality, and Virtual reality, that make games more exciting & give real time experience to users. We help you in launching a <b>gaming platform</b> that allows users to buy, sell & trade game assets via NFTs.
                        </p>
                        
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img loading="lazy" src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Metaverse NFT Marketplace Development</h3>
                        <p>Showcase your collectibles & digital assets by creating your own unique  <b>Metaverse NFT Marketplace</b>. Our NFT marketplaces inbuilt with advanced features, support tokenization and trading of all types of digital lands, 3D Avatar Mapping, and in-game assets .
                        </p>
                       
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img loading="lazy" src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Metaverse App Development</h3>
                        <p> <b>Build your own Metaverse</b> Application with our premium App Development services. We help you in creating <b>Metaverse apps</b> based on every industrial domain, like- Gaming, Real estate, Education, Entertainment, Office, Phygital Fashion, Digital Assets Trade, Marketing & Sponsored Ads, Social media etc, per your specific needs.
                        </p>
                        
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img loading="lazy" src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> 3D Virtual Event</h3>
                        <p>Leveraging our <b>3D Virtual Event Development Services</b>, we are experts in building immersive 3D space equipped with revolutionary techniques such as 3D visualization, reconstruction & others.</p>
                        
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img loading="lazy" src="assets/images/service/s6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> Metaverse Integration Services                        </h3>
                        <p>Our team helps you in improving the functionality of existing <b>Metaverse platforms</b> and  drive a more immersive 3D experience.                        </p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-company-area bg-color">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>About Metaverse</span>
                        <h2>What is Metaverse?</h2>
                        <p>Metaverse is a 3D virtual digital space where people can interact seamlessly with the aspects of Physical & Digital (Phygitial)Virtual World. It provides users an immersive environment and combines the elements of augmented reality (AR) and virtual reality (VR). Metaverse mapping uncannily reflects realistic 3D environs for high-precision autonomous simulation & building of economies.</p>
                        <h2>Metaverse Enterprise Solutions</h2>
                        <p>Quest Global Technologies is the leading <b>Metaverse Development Company</b>, having unmatched expertise in Blockchain, augmented reality, and virtual reality, we have developed various metaverse applications like 3D virtual space, Metaverse gaming platform, Metaverse NFT Marketplaces and decentralized platforms. The Integration of Metaverse in business can create a more immersive buying experience for users, they will experience a more realistic world that they never experienced before. Our projects like - Jokerverse, have successfully received funding from Polygon (Matic)</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img loading="lazy" src="assets/images/company/company-img.png" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 for-order">
                <div class="company-img">
                    <img loading="lazy" src="assets/images/company/ar.png" alt="company" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>About AR VR</span>
                        <h2>What is AR VR?</h2>
                        <p>AR (Augmented Reality) and VR (Virtual Reality) are the latest immersive technologies that bridge digital and physical worlds & enable users to interact with programmable environments and objects. AR enhances the user's view of the real world by overlaying coded information, while VR creates completely realistic digital environments for the user to immerse themselves in. Both ARVR are being used to improve customer engagement & experience by provision of simulative operations & enhanced sensory appeal. Industries from -Healthcare, Entertainment, Automotive, Education, Space & Military, Architecture, Digital Marketing, Education and Healthcare- are all experiencing major breakthroughs via implementation of ARVR Technologies.  </p>
                        <p>Augmented Reality (AR) and Virtual Reality (VR) technologies offer a wide range of benefits, including improved training and education, increased engagement and interaction in entertainment, and new opportunities for businesses to connect with their customers. They can also be used to visualize complex data, designs, and plans in various industries, such as construction, engineering, architecture, healthcare and many more. Additionally, they can help businesses to increase efficiency and streamline operations by providing remote collaboration and visualization tools.</p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section>



<section class="home-process-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Working Process</span>
            <h2>We Are Popular Because of Our Intensive Working Methodology</h2>
            <p></p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img loading="lazy" src="assets/images/process/p1.png" alt="process" />
                        <span><img loading="lazy" src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Research Product</h3>
                        <p>We understand your requirements & map out the workflow.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img loading="lazy" src="assets/images/process/p2.png" alt="process" />
                        <span class="pro-span"><img loading="lazy" src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Development</h3>
                        <p>Strategy planning, solution design & development process takes place.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img loading="lazy" src="assets/images/process/p3.png" alt="process" />
                        <span><img loading="lazy" src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Product Launch</h3>
                        <p>With our dedicated efforts we'll launch you new solution to the market & handover project to you.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img loading="lazy" src="assets/images/process/p4.png" alt="process" />
                    </div>

                    <div class="content">
                        <h3>Customer Service & Support</h3>
                        <p>Our team address your issues and resolve them quickly and in efficient manner.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>







<section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span>Testimonials</span>
            <h2>What Our Client’s Say</h2>
            <p></p>
        </div>
        <div class="client-wrap">
            <owl-carousel-o [options]="clientWrap">
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img loading="lazy" src="assets/images/client/1.jpg" alt="img">
                        <p>Excellent Team of professionals, Did Web Research, Web Development for me very well.</p>
                        <h3>- Yosi R. Israel</h3>
                        <span>Grandier Co.</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img loading="lazy" src="assets/images/client/2.png" alt="img">
                        <p>Brilliant work on my Android APP. Designing and support were awesome.</p>
                        <h3>- Ujjwal Patni</h3>
                        <span>Jamaica International</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img loading="lazy" src="assets/images/client/3.png" alt="img">
                        <p>“Professional, courteous, competitively priced, prompt, and talented. Thanks for the excellent work.”</p>
                        <h3>- MD, Bakartee</h3>
                        <span>Kreutz Airlines</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img loading="lazy" src="assets/images/client/4.jpg" alt="img">
                        <p>Fortunate to find a technical team so strong.</p>
                        <h3>- John T. US</h3>
                        <span>Alien Ltd.</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img loading="lazy" src="assets/images/client/5.jpg" alt="img">
                        <p>Thanks for nicely built system, designed for us. Our entire process has become very smooth after adoption of the system.</p>
                        <h3>- Abhishek A. India</h3>
                        
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-client">
                        <img loading="lazy" src="assets/images/client/6.jpg" alt="img">
                        <p>Excellent vendor! Understands and communicates in English perfectly which was great for our team here in the US. Very quick work and highest of quality. Responded well to requests for changes.</p>
                        <h3>- Rodney A.US</h3>
                        
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-client">
                        <img loading="lazy" src="assets/images/client/7.jpg" alt="img">
                        <p>We appreciate all the work you and your team did so far. Your team have responded very fast to the changes we requested and we really appreciate this.</p>
                        <h3>- Javier C. Australia</h3>
                        
                    </div>
                </ng-template>

            </owl-carousel-o>
        </div>
    </div>
</section>





<section class="home-contact-area bg-color ptb-100" id="contact-us" >
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>Let Us Know About Your Project Idea!</h2>
            <p></p>
        </div>

        <div class="row align-items-center" #target>
            <!-- <div class="col-lg-6 col-md-6">
                <div class="contact-img">
                    <img src="assets/images/contact-img.png" alt="contact">
                </div>
            </div> -->

            <div class="col-lg-8 col-md-8 m-auto">
                <div class="register-form content">
                    <form [formGroup]="phoneForm" (ngSubmit)="onSubmit()">

                        <div class="form-group">
                            <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && phoneForm.controls['name'].errors }" placeholder="Name"/>
                            <div *ngIf="submitted && phoneForm.controls['name'].errors" class="invalid-feedback">
                            <div *ngIf="phoneForm.controls['name'].errors?.['required']">Name is required</div>
                            <div *ngIf="phoneForm.controls['name'].errors?.['maxlength']">
                                Name cannot be more than 35 Characters.
                              </div>
                            </div>
                        </div>
                  
                  
                        <div class="form-group">
                            <input type="text" formControlName="email" class="form-control" placeholder="Email" [ngClass]="{ 'is-invalid': submitted && phoneForm.controls['email'].errors }"/>
                            <div *ngIf="submitted && phoneForm.controls['email'].errors" class="invalid-feedback">
                                <div *ngIf="phoneForm.controls['email'].errors?.['required']">Email is required</div>
                                <div *ngIf="phoneForm.controls['email'].errors?.['email']">Please enter valid email id.</div>
                                <div *ngIf="phoneForm.controls['email'].errors?.['maxlength']">
                                    Email Id cannot be more than 35 Characters.
                                  </div>
                            </div>
                        </div>

                        <ngx-intl-tel-input
                            [cssClass]="'form-control'"
                            [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [searchCountryField]="[
                            SearchCountryField.Iso2,
                            SearchCountryField.Name,
                            SearchCountryField.All
                            ]"
                            [selectFirstCountry]="false"
                            [selectedCountryISO]="CountryISO.UnitedStates"
                            
                            [separateDialCode]="separateDialCode"
                            
                            name="phone"
                            formControlName="phone"
                        >
                        <!-- //[numberFormat]="PhoneNumberFormat.National" -->
                        </ngx-intl-tel-input>
                        <div style="margin-top: 10px; margin-bottom: 10px">
                            <small
                            class="alert-danger"
                            *ngIf="
                            submitted && !phoneForm.controls['phone'].valid
                            " style="--bs-alert-bg: #ffffff!important;--bs-alert-border-color: #ffffff!important;color:#dc3545!important;"
                            >
                            <div *ngIf="phoneForm.controls['phone'].errors">
                                Please enter valid phone number
                              </div>
                            </small>
                        </div>
                        <div class="form-group">
                            <textarea formControlName="message" class="form-control" [ngClass]="{ 'is-invalid': submitted && phoneForm.controls['message'].errors }" placeholder="Purpose" ></textarea>
                            <div *ngIf="submitted && phoneForm.controls['message'].errors" class="invalid-feedback">
                                <div *ngIf="phoneForm.controls['message'].errors?.['required']">Purpose is required</div>
                                
                                <div *ngIf="phoneForm.controls['message'].errors?.['maxlength']">
                                    Max 50 characters. Let's explore the rest via call.
                                </div>
                              </div>
                        </div>
                        

                        <!-- <mat-form-field appearance="fill" floatLabel="always">
                            <mat-label>Telephone</mat-label>
                            <input matInput ng2TelInput>
                            
                          </mat-form-field> -->
                  
                      <!-- <div class="form-group">
                        <label>Password</label>
                        <input
                          type="password"
                          formControlName="password"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
                        />
                        <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                          <div *ngIf="f['password'].errors['required']">Password is required</div>
                          <div *ngIf="f['password'].errors['minlength']">
                            Password must be at least 6 characters
                          </div>
                          <div *ngIf="f['password'].errors['maxlength']">
                            Username must not exceed 40 characters
                          </div>
                        </div>
                      </div> -->
                  
                      <!-- <div class="form-group">
                        <label>Confirm Password</label>
                        <input
                          type="password"
                          formControlName="confirmPassword"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f['confirmPassword'].errors }"
                        />
                        <div
                          *ngIf="submitted && f['confirmPassword'].errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f['confirmPassword'].errors['required']">
                            Confirm Password is required
                          </div>
                          <div *ngIf="f['confirmPassword'].errors['matching']">
                            Confirm Password does not match
                          </div>
                        </div>
                      </div> -->
                  
                      <!-- <div class="form-group form-check">
                        <input
                          type="checkbox"
                          formControlName="acceptTerms"
                          class="form-check-input"
                          [ngClass]="{ 'is-invalid': submitted && f['acceptTerms'].errors }"
                        />
                        <label for="acceptTerms" class="form-check-label"
                          >I have read and agree to the Terms</label
                        >
                        <div *ngIf="submitted && f['acceptTerms'].errors" class="invalid-feedback">
                          Accept Terms is required
                        </div>
                      </div> -->
                  
                      <!-- <div class="form-group">
                        <button type="submit" class="btn btn-primary">Register</button>
                        <button
                          type="button"
                          (click)="onReset()"
                          class="btn btn-warning float-right"
                        >
                          Reset
                        </button>
                      </div> -->
                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="default-btn page-btn box-btn">Submit</button>
                        </div>
                    </form>
                  </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="home-contact-area bg-color ptb-100" id="contact-us">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>Let Us Know About Your Project Idea!</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque blanditiis exercitationem incidunt, alias corporis quam assumenda dicta.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="contact-img">
                    <img src="assets/images/contact-img.png" alt="contact">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn page-btn box-btn">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section> -->